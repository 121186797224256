<template >
  <div>
    <v-container >
      <v-card class="mx-auto pa-5 mt-5" max-width="1264" min-height="84vh" elevation="4">
        <!-- <v-carousel cycle class="mb-5"  height="30vh" hide-delimiter-background show-arrows>
          <v-carousel-item reverse-transition="fade-transition" transition="fade-transition">
            <v-img height="29vh" contain
            src="https://firebasestorage.googleapis.com/v0/b/pediidos-f9d72.appspot.com/o/misitio%2Flogos-pediidos%2Fslide_pediidos-01.jpg?alt=media"
            ></v-img>
          </v-carousel-item>
          <v-carousel-item reverse-transition="fade-transition" transition="fade-transition">
            <v-img height="29vh" contain
            src="https://firebasestorage.googleapis.com/v0/b/servi-app-ec.appspot.com/o/misitio%2Fbanners%2F004.png?alt=media"
            ></v-img>
          </v-carousel-item>
        </v-carousel> -->
        <v-sheet v-if="shopkeepers.length > 0" >
          <p class="headline font-weight-medium pediidos--text mt-15 mb-10 ">{{$t('home.wellcome_title')}}</p>
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" v-for=" shop in shopkeepers" :key="shop.id" >
                <v-hover v-slot="{ hover }" >
                  <v-card @click="Router(shop.id)"
                  min-height="145" max-height="145"
                  :color=" hover ? 'grey lighten-4' : 'grey lighten-5' "
                  :elevation="hover ? 6 : 4" class="d-flex flex-column justify-center">
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <v-row >
                        <v-card-title class="title ma-5" style="word-break: normal;"
                          v-text="shop.name"
                        ></v-card-title>
                      </v-row>
                      <v-avatar class="ma-3" size="100"  >
                        <v-img :src="shop.picture"></v-img>
                      </v-avatar>
                    </div>
                  </v-card>
                </v-hover>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-else class="text-center align-center justify-center " >
          <v-icon size="250" style="margin-top: 80px;" >mdi-map-search</v-icon>
          <p class=" my-5" >{{$t('home.msg_empty_geo')}}</p>
        </v-sheet>
      </v-card>
    </v-container>
  </div>
</template>

<script type="text/javascript">
  import Api from '@/helpers/api.js';
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'Distributors',
    data() {
      return {
        shopkeepers: [],
      }
    },
    async mounted(){
      this.getGeo(this.user.lat,this.user.lon);
    },
    methods: {
      ...mapMutations(['APP_LOADER',]),
      async getGeo(lat,lon) {
        this.APP_LOADER(true);
        Api.post('shopkeepers/geo/', {latitude:lat,longitude:lon}).then((result)=> {
          if(result.status < 300) {
              this.shopkeepers = result.data;
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        }).finally(()=>{
          this.APP_LOADER(false);
        })
      },
      Router(shop_id) {
        this.$router.push('/home/'+shop_id+'/price-list');
      },
    },
    computed: {
      ...mapState(['user',]),
    },
  }
</script>

<style type="text/css" scoped>
</style>